import React, { useEffect, useState } from 'react';
import Savings from "../../assets/Savings.gif";
import { Card, CardContent, CardHeader, Grid, Typography, Button, Collapse, CardActions, CardMedia } from '@mui/material';
import { getAllService } from '../../service/api.service';

const ServiceCard = ({ service }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  

  return (
    <div style={{
      display: "block",
      justifyContent: "center",
    }}
    >
      <Card sx={{
        maxWidth: 345,
        m: 1.5,
        boxShadow: 2,
        borderRadius: 6,
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: 4,
        },
      }}>
        <CardMedia
          component="img"
          sx={{
            height: 80,
            objectFit: "contain",
            margin: "0 auto",
            padding: 1.5,
          }}
          image={Savings}
          title="Saving"
        />
        <CardHeader 
          title={service.name} 
          subheader={service.type} 
          sx={{ padding: 1.5 }}
        />
        <CardContent sx={{ padding: 1.5 }}>
          <Typography variant="body2" color="textSecondary">
            Amount: {service.amount}
          </Typography>
          <Button onClick={handleExpandClick} size="small" style={{ marginBottom: '10px' }}>
            {expanded ? 'Hide Details' : 'Show Details'}
          </Button>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {service.type === 'savings' && (
              <>
                <Typography variant="body2" color="textSecondary">
                  Target Amount: {service.saving?.targetAmount}
                </Typography>
              </>
            )}
            {service.type === 'loan' && (
              <>
                <Typography variant="body2" color="textSecondary">
                  Loan Amount: {service.loan?.loanAmount}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Interest Rate: {service.loan?.interestRate}%
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Duration: {service.loan?.duration} months
                </Typography>
              </>
            )}
            <Typography variant="body2" color="textSecondary">
              Collection Frequency: {service.collectionFrequency}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Description: {service.description}
            </Typography>
          </Collapse>
        </CardContent>
      </Card>
    </div>
  );
};

const ServicesCard = () => {
  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getAllService({ page, limit }).then(res => setServices(res?.services?.services));
  }, [page, limit]);

  return (
    <Grid container spacing={3}>
      {services?.map((service, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesCard;
