export const USER_ROUTES = {
  REGISTER: `user/register`,
  LOGIN: `user/login`,
  // Add more API endpoints as needed
};

export const CUSTOMER_ROUTES = {
  GET_CUSTOMER: `customer/`,
  REGISTER: `customer/add`,
  LIST_CUSTOMERS: `customer/all`,
  SEARCH_CUSTOMERS: `customer/search`,
  SUBSCRIBE:`customer/subscribe`,
  DEACTIVATE_SUBS:`customer/:subscriptionId/deactivate`,
  LIST_SERVICES:`customer/:customerId/services`,
  DELETE_CUSTOMER:`customer/delete`,
  UPDATE:`customer/update`
  
  // Add more API endpoints as needed
};

export const SERVICES_ROUTES = {
  ADD: `service/add`,
  LIST_SERVICES: `service/all`,
  // Add more API endpoints as needed
};

export const TRANSACTION_ROUTES = {
  ADD: `transaction/`,
  LIST_TRANSACTIONS: `transaction/`,
  DELETE:`transaction/`
  // Add more API endpoints as needed
};