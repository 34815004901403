import { MENU } from "../utils/constants";
import { getMenuPermissions } from "../utils/helpers";

const useCustomerDetails = () => {
    const permissions = getMenuPermissions(MENU?.CUSTOMER_DETAILS);
    return {
        permissions
    }
};

export default useCustomerDetails;
