import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import dayjs, { Dayjs } from "dayjs";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AccordionActions from "@mui/material/AccordionActions";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  getCustomerServices,
  getCustomerBy,
  updateCustomer,
} from "../../../service/api.service";
import useTransactions from "../../../hooks/useTransactions";
import useCustomerDetails from "../../../hooks/useCustomerDetails";

const style = {
  margin: "5rem 0rem 5rem 0rem",
};

export default function CustomersList() {
  const [expanded, setExpanded] = useState(true);
  const [editing, setEditing] = useState(false);
  const { custID } = useParams();
  const [customer, setCustomer] = useState({});
  const [service, setService] = useState({});
  const { permissions: custPermissions } = useCustomerDetails();
  const navigate = useNavigate();
  const fetchDataName = async () => {
    try {
      const response = await getCustomerBy({ customerId: custID });
      if (response) {
        setCustomer({
          ...response,
          accountNumber: response?.accounts[0]?.accountNumber || "",
        });
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await getCustomerServices({ customerId: custID });
      if (response) {
        setService({
          ...response,
          serviceType: response?.serviceType || "",
        });
      }
    } catch (error) {
      console.error("Error fetching service id:", error);
    }
  };

  useEffect(() => {
    if(!custPermissions?.READ){
      navigate(-1);
    }
  }, [])

  useEffect(() => {
    fetchDataName();
    fetchServices();
  }, [custID]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        relativeName: customer.relativeName,
        nominee: customer.nominee,
        occupation: customer.occupation,
        address: customer.address,
        phoneNumber: customer.phoneNumber,
        aadhar: customer.aadhar,
        pan: customer.pan,
        serviceId: customer.serviceId,
      };

      await updateCustomer({ customerId: custID, data: updatedData });
      setEditing(false);
      fetchDataName();
    } catch (error) {
      console.error("Error updating customer data:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB").format(date);
  };

  return custPermissions?.READ ? (
    <React.Fragment>
      <Container>
        <Box sx={style}>
          <Card>
            <Accordion expanded={expanded}>
              <AccordionSummary
                expandIcon={
                  <VerifiedUserIcon
                    style={{ transform: "rotate(180deg)", color: "#4aa92c" }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Account Number: {`${customer?.accountNumber}`}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {`${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {/* {`${service?.serviceType}`} */}
                  </Typography>
                </CardContent>
              </AccordionSummary>
              <AccordionDetails>
                <CardContent>
                  <Box>
                    <Grid style={{ margin: "0rem 0rem 1.5rem 0rem" }}>
                      <Typography variant="h6" gutterBottom>
                        Full Details
                      </Typography>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="date"
                          name="date"
                          label="Date"
                          variant="outlined"
                          size="small"
                          value={formatDate(customer?.date ?? "")}
                          disabled={!editing}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={customer?.firstName ?? ""}
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={customer?.lastName ?? ""}
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          size="small"
                          value={customer?.email ?? "N/A"}
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="relativeName"
                          name="relativeName"
                          label="Father / Spouse Name"
                          size="small"
                          value={customer?.relativeName ?? "N/A"}
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="nominee"
                          name="nominee"
                          label="Nominee"
                          size="small"
                          value={customer?.nominee ?? "N/A"}
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="occupation"
                          name="occupation"
                          label="Occupation"
                          value={customer?.occupation ?? "N/A"}
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="address"
                          name="address"
                          label="Address"
                          value={customer?.address ?? "N/A"}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          label="Phone Number"
                          value={customer?.phoneNumber ?? "N/A"}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="aadhar"
                          name="aadhar"
                          label="AADHAR"
                          value={customer?.aadhar ?? "N/A"}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="pan"
                          name="pan"
                          label="PAN"
                          value={customer?.pan ?? "N/A"}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="serviceId"
                          name="serviceId"
                          label="Service Name"
                          size="small"
                          value={customer?.serviceId ?? "N/A"}
                          fullWidth
                          variant="outlined"
                          onChange={handleInputChange}
                          disabled={!editing}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </AccordionDetails>
              <AccordionActions>
                <Button onClick={() => setEditing(false)}>Cancel</Button>
                {editing ? (
                  <Button onClick={handleSave}>SAVE</Button>
                ) : (
                  <Button onClick={handleEdit}>Edit</Button>
                )}
              </AccordionActions>
            </Accordion>
          </Card>
        </Box>
      </Container>
    </React.Fragment>
  ) : (
    navigate(-1)
  );
}
