const decodeJWT = (token) => {
    try {
        const parts = token.split('.'); // Split the JWT into its parts
        if (parts.length !== 3) {
            throw new Error('Invalid token format');
        }

        const base64Url = parts[1]; // Get the payload (second part of the token)
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
                .join('')
        );

        return JSON.parse(jsonPayload); // Parse the JSON payload
    } catch (error) {
        console.error('Failed to decode token:', error);
        return null;
    }
};

const getMenuPermissions = (menuID, parentID) => {
    const token = localStorage.getItem('token');
    let permissions = {};
    if (token) {
        const { user } = decodeJWT(token);
        permissions = parentID ? user?.role?.permissions?.[parentID]?.[menuID] : user?.role?.permissions?.[menuID];
    } 
    return {
        READ: permissions?.r || false,
        CREATE: permissions?.c || false,
        EDIT: permissions?.e || false,
        DELETE: permissions?.d || false,
        APPROVE: permissions?.a || false
    };
}

export { decodeJWT, getMenuPermissions };
