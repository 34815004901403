import { MENU } from "../utils/constants";
import { getMenuPermissions } from "../utils/helpers";

const useServices = () => {
    const permissions = getMenuPermissions(MENU?.SERVICES);
    return {
        permissions
    }
};

export default useServices;
