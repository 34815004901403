import React, { useState } from "react";
import SearchBar from "../Search/SearchBar.tsx";
import RunningAmountView from "../RunningAmountView/RunningAmountView.tsx";
import PolicyCard from "../PolicyCard/PolicyCard.tsx";
import DataTable from "./DataTable/DataTable.tsx";
import { Container } from "@mui/material";
import ServicesCard from "../../components/ServicesCard/ServicesCard.tsx";
import useTransactions from "../../hooks/useTransactions.jsx";
import useDashboard from "../../hooks/useDashboard.jsx";
import useServices from "../../hooks/useServices.jsx";

function Home() {
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { permissions: txnPermissions } = useTransactions();
  const { permissions: servicePermissions } = useServices();
  const { permissions: dashboardPermissions } = useDashboard();
  return (
    <div>
      <SearchBar setSearch={setSearchValue} setRefresh={setRefresh} />
      {dashboardPermissions?.ADMIN? <RunningAmountView />: null}
      {servicePermissions?.READ? <ServicesCard />: null}
      {txnPermissions?.READ ? (
        <DataTable
          search={searchValue}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
    </div>
  );
}

export default Home;
