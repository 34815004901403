import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import AddCustomer from "../AddCustomer/AddCustomer.tsx";
import AddTransaction from "../AddTransaction/AddTransaction.tsx";
import { getAllCustomers } from "../../service/api.service";
import useTransactions from "../../hooks/useTransactions.jsx";
import useDashboard from "../../hooks/useDashboard.jsx";
import useCustomerDetails from "../../hooks/useCustomerDetails.jsx";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: theme.spacing(3),
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.black,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "30ch",
    "&:focus": {
      width: "30ch",
    },
    color: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default function SearchBar(props: any) {
  const { setSearch, setRefresh } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { permissions: txnPermissions } = useTransactions();
  const { permissions: custPermissions } = useCustomerDetails();
  const { permissions: dashboardPermissions } = useDashboard();

  const handleSearch = async (query) => {
    try {
      const searchResults = await getAllCustomers(query);
      console.log("Search Results:", searchResults);
    } catch (error) {
      console.error("Search failed:", error);
    }
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearch(query);
  };

  return (
    <Container>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: isSmallScreen ? "0rem" : "4rem",
        }}
      >
        {dashboardPermissions?.ADMIN ? (
          <Toolbar>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Customer…"
                inputProps={{ "aria-label": "search" }}
                onChange={handleInputChange}
              />
            </Search>
          </Toolbar>
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {custPermissions?.CREATE ? (
            <AddCustomer setRefresh={setRefresh} />
          ) : null}
          {txnPermissions?.CREATE ? (
            <AddTransaction setRefresh={setRefresh} />
          ) : null}
        </Box>
      </Box>
    </Container>
  );
}
