// apiService.js
import axios from 'axios';
import { USER_ROUTES, CUSTOMER_ROUTES, SERVICES_ROUTES, TRANSACTION_ROUTES } from '../config/api.config';

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const authService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set your API base URL
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
  },
});

authService.interceptors.request.use(function (config) {
  const token = `Bearer ${localStorage.getItem('token') || ''}`;
  config.headers.Authorization =  token;
   
  return config;
});

authService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the response status is 401
    if (error.response.status === 401) {
      // Handle the 401 error, for example, remove the token from localStorage
      localStorage.removeItem('token');
      // Redirect or perform any other action as needed
      // For example, you can redirect the user to the login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const registerUser = async (userData) => {
  try {
    const response = await apiService.post(USER_ROUTES.REGISTER, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await apiService.post(USER_ROUTES.LOGIN, userData);
    if (response?.data?.token) {
      localStorage.setItem("token", response.token);
      window.location.href = '/';
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};


//.................AUTHENTICATED ROUTES.................//
//.................CUSTOMER ROUTES.................//
export const createCustomer = async (customerData) => {
  try {
    const response = await authService.post(CUSTOMER_ROUTES.REGISTER, customerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchCustomer = async ({ search, page, limit }) => {
  try {
    let query = new URLSearchParams();
    if (search) query.append('search', search);
    if (page) query.append("page", page);
    if (limit) query.append("limit", limit);
    const response = await authService.get(`${CUSTOMER_ROUTES.SEARCH_CUSTOMERS}?${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllCustomers = async ({ search, page, limit }) => {
  try {
    let query = new URLSearchParams();
    if (search) query.append('search', search);
    if (page) query.append("page", page);
    if (limit) query.append("limit", limit);
    const response = await authService.get(`${CUSTOMER_ROUTES.LIST_CUSTOMERS}?${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getCustomerBy = async ({ customerId, account }) => {
  try {
    let query = new URLSearchParams();
    if (customerId) query.append('customerId', customerId);
    if (account) query.append("accountNumber", account);
    const response = await authService.get(`${CUSTOMER_ROUTES.GET_CUSTOMER}?${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const subscribeService = async ({ customerId, service, startDate, endDate, additionalDetails }) => {
  try {
    let data = { customerId, service, startDate, endDate, additionalDetails }
    const response = await authService.post(CUSTOMER_ROUTES.SUBSCRIBE, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deactivateSubs = async ({ subscriptionId }) => {
  try {
    const response = await authService.put(`${CUSTOMER_ROUTES.DEACTIVATE_SUBS}?subscriptionId=${subscriptionId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getCustomerServices = async ({ customerId, isActive, page, limit }) => {
  try {
    let query = new URLSearchParams();
    if (customerId) query.append('customerId', customerId);
    if (isActive !== undefined) query.append("isActive", isActive);
    if (page) query.append("page", page);
    if (limit) query.append("limit", limit);
    const response = await authService.get(`${CUSTOMER_ROUTES.LIST_SERVICES?.replace(":customerId", customerId)}?${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteCustomerById = async ({ customerId }) => {
  try {
    const response = await authService.delete(`${CUSTOMER_ROUTES.DELETE_CUSTOMER}/${customerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateCustomer = async ({ customerId, data }) => {
  const response = await authService.put(`${CUSTOMER_ROUTES?.UPDATE}/${customerId}`, data);
  return response;
}
//.................SERVICES ROUTES.................//
export const addService = async ({ name, type, amount, isActive, saving, loan, collectionFrequency, description }) => {
  try {
    let data = { name, type, amount, isActive, saving, loan, collectionFrequency, description }
    const response = await authService.post(SERVICES_ROUTES.ADD, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllService = async ({ isActive, serviceType, page, limit }) => {
  try {
    const query = new URLSearchParams();
    if (isActive !== undefined) query.append("isActive", isActive);
    if (serviceType) query.append("serviceType", serviceType);
    if (page) query.append("page", page);
    if (limit) query.append("limit", limit);
    const response = await authService.get(`${SERVICES_ROUTES.LIST_SERVICES}?${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
//.................TRANSACTION ROUTES.................//
export const addTransaction = async ({ customerId, serviceId, amount, type, description, ...rest }) => {
  try {
    let data = { customerId, serviceId, amount, type, description, ...rest }
    const response = await authService.post(TRANSACTION_ROUTES.ADD, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllTransactions = async ({ customerId, serviceId, type, startDate, endDate, page, limit }) => {
  try {
    const query = new URLSearchParams();
    if (customerId) query.append("customerId", customerId);
    if (serviceId) query.append("serviceId", serviceId);
    if (type) query.append("type", type);
    if (startDate) query.append("startDate", startDate);
    if (endDate) query.append("endDate", endDate);
    if (page) query.append("page", page);
    if (limit) query.append("limit", limit);
    const response = await authService.get(`${TRANSACTION_ROUTES.LIST_TRANSACTIONS}?${query}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteTransaction = async (transactionId) => {
  const response = await authService.delete(`${TRANSACTION_ROUTES?.DELETE}${transactionId}`);
  return response;
}