import { createTheme } from "@mui/material";
import BG_Theme from "../assets/BG_Theme.svg";

export const colors = {
    //Palette#1
    $paynesGray: '#577590ff',
    $saffron: '#f3ca40ff',
    $gamboge: '#f2a541ff',
    $atomicTangerine: '#f08a4bff',
    $coralPink: '#d78a76ff',

    //Palette#2
    $charcoal: '#28536bff',
    $rosyBrown: '#c2948aff',
    $airSuperiorityBlue: '#7ea8beff',
    $isabelline: '#f6f0edff',
    $khaki: '#bbb193ff'
}

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.$charcoal,
            contrastText: colors.$isabelline,
        },
        secondary: {
            main: colors.$isabelline,
            contrastText: colors.$charcoal,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: `url(${BG_Theme})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    minHeight: "100vh",
                },
            },
        },
    },
});