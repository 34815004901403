import { MENU } from "../utils/constants";
import { getMenuPermissions } from "../utils/helpers";

const useTransactions = () => {
    const permissions = getMenuPermissions(MENU?.TRANSACTION);
    return {
        permissions
    }
};

export default useTransactions;
