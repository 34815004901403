import { MENU } from "../utils/constants";
import { getMenuPermissions } from "../utils/helpers";

const useDashboard = () => {
    const permissions = getMenuPermissions(MENU?.DASHBOARD);
    return {
        permissions: {
            ADMIN: permissions?.APPROVE,
            DEFAULT: permissions?.DELETE
        }
    }
};

export default useDashboard;
